<template>
  <a-modal title="关联业务" v-model="visible" cancelText="取消" okText="确认" width="900px"
    @ok="handleOk" @cancel="handleCancel" :zIndex='4200'>
        <div class="main-box">
            <div class="search">
                <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-input v-model='searchParams.search.customer_name'  style="width: 140px" allowClear placeholder="客户名称"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-input v-model='searchParams.search.cellphone'   style="width: 140px" allowClear placeholder="联系电话"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchList" html-type="submit" type="primary">搜索</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visibleF">
                        <template slot="content">
                            <div class="more-search" style="width:740px">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="所属校区">
                                    <a-select :dropdownStyle='{zIndex:4200}' v-model='searchParams.search.studio_id' mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="来源渠道">
                                    <a-tree-select
                                        v-model="searchParams.search.channel_id"
                                        :tree-data="channels"
                                        tree-checkable
                                        allowClear
                                        treeNodeFilterProp="title"
                                        :maxTagCount='1'
                                        :maxTagTextLength='5'
                                        :dropdownStyle="{zIndex:4300,maxHeight:'400px'}"
                                        :show-checked-strategy="SHOW_ALL"
                                        placeholder="请选择来源渠道"
                                    />
                                </a-form-item>
                                <a-form-item class="block-line" label="意向科目">
                                    <a-select :dropdownStyle='{zIndex:4200}' v-model='searchParams.search.course_cate_id' allowClear showSearch placeholder="请选择意向科目" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="意向程度">
                                    <a-select :dropdownStyle='{zIndex:4200}' v-model='searchParams.search.intention_level' allowClear placeholder="请选择意向程度">
                                    <a-select-option v-for="(d, index) of intentionLevels" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="学员类型">
                                    <a-select :dropdownStyle='{zIndex:4200}' v-model='searchParams.search.deal_status' allowClear placeholder="请选择学员类型">
                                    <a-select-option v-for="(d, index) of studentTypes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="跟进状态">
                                    <a-select :dropdownStyle='{zIndex:4200}' mode="multiple" v-model='searchParams.search.task_step' allowClear placeholder="请选择跟进状态">
                                    <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="到访状态">
                                    <a-select :dropdownStyle='{zIndex:4200}' v-model='searchParams.search.is_attend' allowClear placeholder="请选择到访状态">
                                    <a-select-option v-for="(d, index) of isAttends" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                                <a-form-item class="block-line" label="诺访时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.promise'
                                        allowClear
                                        :popupStyle='{zIndex:4200}'
                                        @change="(val,time)=>handleTime(val,time,'promise')" />
                                </a-form-item>
                                <a-form-item class="block-line" label="回访时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.next'
                                        allowClear
                                        :popupStyle='{zIndex:4200}'
                                        @change="(val,time)=>handleTime(val,time,'next')" />
                                </a-form-item>
                                <a-form-item class="block-line" label="分配时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.distribute'
                                        allowClear
                                        :popupStyle='{zIndex:4200}'
                                        @change="(val,time)=>handleTime(val,time,'distribute')" />
                                </a-form-item>

                                <a-form-item class="block-line" label="到访时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.attend'
                                        allowClear
                                        :popupStyle='{zIndex:4200}'
                                        @change="(val,time)=>handleTime(val,time,'attend')" />
                                </a-form-item>
                                <a-form-item class="block-line" label="最后跟进">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.update'
                                        allowClear
                                        :popupStyle='{zIndex:4200}'
                                        @change="(val,time)=>handleTime(val,time,'update')" />
                                </a-form-item>
                                <a-form-item class="block-line" label="添加时间">
                                        <a-range-picker
                                        :ranges="rangesData"
                                        v-model='searchDatas.add'
                                        allowClear
                                        :popupStyle='{zIndex:4200}'
                                        @change="(val,time)=>handleTime(val,time,'add')" />
                                </a-form-item>
                                <a-form-item class="block-line" label="添加人">
                                    <a-select :dropdownStyle='{zIndex:4200}' mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='searchParams.search.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="searchList">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                </a-form>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="customer_id"
                    :selection="{key: 'key'}"
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    :columns="columns" :dataSource="list" :scroll="{ x: 800 }" :customRow="clickRow">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="level" slot-scope="text, record">
                        <div>
                            <a-dropdown placement="bottomCenter" :trigger="['click']">
                                <span style='cursor:pointer' @click="e => e.preventDefault()" v-if='text == null'>--</span>
                                <span v-if='text == item.value' @click="e => e.preventDefault()" class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}" v-for="item in intentionLevels" :key="item.value">{{item.label}}</span>
                                <a-menu slot="overlay" class='customer-menu'>
                                    <a-menu-item v-for="item in intentionLevels" :key='item.value'>
                                        <span class="follow-intentionLevels" :style="{color:text == item.value?'#fff':item.color,background:text == item.value?item.color:'#fff'}">{{item.label}}</span>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
  
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
  {title: '客户名称', width:'80px', dataIndex: 'customer_name', key: '1' ,ellipsis: true},
  {title: '联系电话', width:'120px',dataIndex: 'cellphone', key: '3'},
  {title: '来源渠道', dataIndex: 'channel_id', key: '2',ellipsis: true },
  {title: '归属校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis: true},
//   {title: '意向程度', dataIndex: 'intention_level', key: 'intention_level' , scopedSlots: { customRender: 'level' },align:"center"},
  {title: '添加时间', dataIndex: 'created_at', key: 'created_at' },
]

    import tableMixins from '@/common/mixins/table'
    import ranges from "@/common/mixins/ranges"
    import { timeHandle } from '@/utils/timeFormat'
    import moment from 'moment'
    import C_ITEMS from '@/utils/items'
    export default {
        name:'customer',
        inject: ['parent'],
        mixins: [ tableMixins,ranges ],
        data() {
            return {
                visible: false,
                visibleF: false,
                loading: false,
                columns,
                list:[],
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0
                    },
                    "sort": ''
                },
                studios:[],
                channels:[],
                channelsId:[],
                consultants:[],
                staffList:[],
                classCategorys:[],
                isAttends: C_ITEMS.isAttendsF,
                intentionLevels:C_ITEMS.intentionLevelsF,
                studentTypes:C_ITEMS.studentTypes,
                dealStatus:C_ITEMS.dealStatus,
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                searchDatas:{},
            }
        },
        created(){
            this.visible = true
            this.selectedRecord = JSON.parse(this.$ls.get('localSelectedRecord')) || []
            this.selectedRowKeys = JSON.parse(this.$ls.get('localSelectedRowKeys')) || []
            this.getStudio()
            this.getSourceChannel()
            this.getConsultant()
            this.getClassCategory()
            this.getStaff()
        },
        methods: {
            moment,
            timeHandle,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('cCustomerAction', {data: this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getSourceChannel(id) {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
                this.channels = res.data
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchConsultantAction', {})
                this.consultants = res.data
            },
            async getClassCategory() {
                let res = await this.$store.dispatch('searchCourseTypeAction', {})
                this.classCategorys = res.data
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleOk(){
                this.$ls.set('localSelectedRecord',JSON.stringify(this.selectedRecord))
                this.$ls.set('localSelectedRowKeys',JSON.stringify(this.selectedRowKeys))
                this.parent.hideCustomer(1)
            },
            handleCancel(){
                this.parent.hideCustomer()
            },
            reset() {
                this.channelsId = null
                this.searchDatas = {}
                this.searchParams.search = {is_sub:0}
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                let start_date = name + '_start_date'
                let end_date = name + '_end_date'
                this.searchParams.search[start_date] = time[0]
                this.searchParams.search[end_date] = time[1]
            },
            closeSearch() {
                this.visibleF = false
            },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            let index = this.selectedRowKeys.indexOf(record.customer_id)
                            if(index == -1){
                            this.selectedRowKeys.push(record.customer_id)
                            this.selectedRecord.push(record)
                            }else{
                            this.selectedRowKeys.splice(index,1)
                            this.selectedRecord.splice(index,1)
                            }
                        }
                    }
                }
            },
        },

    }
</script>

<style lang="scss">

</style>