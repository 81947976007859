<template>
  <a-modal title="填写报表" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="700px"
    @ok="handleOk" @cancel="handleCancel" class="journal-modal">
    <a-tabs v-if="item" v-model="active" @change="callback">
      <a-tab-pane v-if="active === 1" :key="1" tab="日报"></a-tab-pane>
      <a-tab-pane v-if="active === 2" :key="2" tab="周报"></a-tab-pane>
      <a-tab-pane v-if="active === 3" :key="3" tab="月报"></a-tab-pane>
    </a-tabs>
    <a-tabs v-else v-model="active" @change="callback">
      <a-tab-pane :key="1" tab="日报"></a-tab-pane>
      <a-tab-pane :key="2" tab="周报"></a-tab-pane>
      <a-tab-pane :key="3" tab="月报"></a-tab-pane>
    </a-tabs>
    <div class="table" style="margin-bottom:10px">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
            :columns="totle_columns" :dataSource="follow_total">
        </a-table>
      </div>
    </div>
    <div class="table" style="margin-bottom:10px">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
            :columns="totle_employee_columns" :dataSource="follow_total">
            <template slot="action" slot-scope="text,record">
              <a href="javascript:;" @click="showSubordinate">查看下属</a>
            </template>
        </a-table>
      </div>
    </div>

    <div class="table" style="margin-bottom:10px" v-if="this.app_id == 'mMKcL4'">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="channel_id"
            :columns="columns" :dataSource="list">
        </a-table>
      </div>
    </div>
    <div class="table" style="margin-bottom:10px" v-if="this.app_id == 'mMKcL4'">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="customer_id"
            :columns="columnsF" :dataSource="target_customer">
        </a-table>
      </div>
    </div>

    <a-form :form="form" layout="vertical">
      <a-form-item :label="labelName" >
        <a-textarea v-model="content" :rows="4"  v-decorator="['content', { rules: [{ required: true }] }]" />
      </a-form-item>
      <a-form-item :label="labelNameTwo">
        <a-textarea v-model="tomorrow" :rows="4" v-decorator="['tomorrow', { rules: [{ required: true }] }]" />
      </a-form-item>
      <a-form-item label="遇到的问题">
        <a-textarea v-model="question" :rows="4" v-decorator="['question', { rules: [{ required: true }] }]"/>
      </a-form-item>
      <a-form-item label="添加图片">
        <a-upload
            accept='.png,.jpeg,.jpg'
            list-type="picture-card"
            :file-list="imgList"
            :data="uploadParams"
            :action="uploadUrl"
            :multiple='true'
            :beforeUpload="beforeUploadImg"
            @change="changeImg">
            <div>
                <a-icon type="plus" />
            </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="发送给谁">
        <div>
          <a-popover v-model="isCheckWarp" title="选择" trigger="click">
            <div slot="content">
              <checkWarp />
            </div>
            <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
              <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
              <a-icon style='fontSize:18px' type="plus-circle" />
            </div>
          </a-popover>
        </div>
      </a-form-item>
      <a-form-item label="关联业务">
        <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
          <a-tag style="margin:5px" v-for="item in selectedRecord" :key="item.task_id">{{item.customer_name}}</a-tag>
          <a-icon style='fontSize:18px' @click="isCustomer = true" type="plus-circle" />
        </div>
      </a-form-item>
    </a-form>
    <customer v-if="isCustomer" />
    <subordinate v-if="isSubordinate" />
  </a-modal>
</template>

<script>
const columns = [
  { title: '渠道名称', dataIndex: 'channel_name', key: 'channel_name'},
  { title: '数量', dataIndex: 'follow_count', key: 'follow_count'},
]
const columnsF = [
  { title: '姓名', width:'150px', dataIndex: 'customer_name', key: 'customer_name'},
  { title: '手机号码',width:'150px', dataIndex: 'customer_cellphone', key: 'customer_cellphone'},
  { title: '备注', dataIndex: 'customer_remark', key: 'customer_remark'},
]
const totle_columns = [
  { title: '校区总跟进量', dataIndex: 'studio_follow_total', key: 'studio_follow_total'},
  { title: '校区总诺访', dataIndex: 'studio_promise_total', key: 'studio_promise_total'}
]
const totle_employee_columns = [
  { title: '员工名称', dataIndex: 'employee_name', key: 'employee_name'},
  { title: '个人跟进量', dataIndex: 'employee_follow_total', key: 'employee_follow_total'},
  { title: '个人诺访量', dataIndex: 'employee_promise_total', key: 'employee_promise_total'},
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'action' }}
]
import url from '@/utils/URL'
import checkWarp from './CheckWarp'
import customer from './customer'
import subordinate from './subordinate'
import createFilePath from '@/utils/tools'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
      return {
          parent: this
      }
  },
  props: {
    item: Object
  },
  components: {
    checkWarp,
    customer,
    subordinate
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      confirmLoading: false,
      visible: false,
      loading: false,
      isCheckWarp: false,
      isCustomer: false,
      isSubordinate: false,
      form: this.$form.createForm(this),
      active:1,
      type:'today',
      labelName:'今日工作',
      labelNameTwo:'明日工作',
      imgList:[],
      imgAddr:[],
      uploadParams: {},
      uploadUrl: url.uploadQiNiu,
      content:'',
      tomorrow:'',
      question:'',
      check_name_list:[],
      selectedRecord:[],
      selectedRowKeys:[],
      columns,
      list:[],
      columnsF,
      target_customer:[],
      totle_columns,
      totle_employee_columns,
      follow_total:[],
      employee_id:this.$store.getters.employee_id,
      employee_name:this.$ls.get('realname')
    }
  },
 computed: {
      app_id(){
          return this.$store.getters.brand_info.app_id
      }
  },
  async created() {
    this.$ls.remove('check_name_list')
    this.$ls.remove('send_employee_ids')
    this.$ls.remove('send_employee_list')
    this.$ls.remove('send_structure_ids')
    this.$ls.remove('send_structure_list')
    this.$ls.remove('localSelectedRecord')
    this.$ls.remove('localSelectedRowKeys')
    this.getTodayCrm()
    if(this.item && this.item.category_id){
      if(this.item.category_id === 1){
        this.labelName = '今日工作'
        this.labelNameTwo = '明日工作'
      }
      if(this.item.category_id === 2){
        this.labelName = '本周工作'
        this.labelNameTwo = '下周工作'
      }
      if(this.item.category_id === 3){
        this.labelName = '本月工作'
        this.labelNameTwo = '下月工作'
      }
      this.active = this.item.category_id
      this.content = this.item.content
      this.tomorrow = this.item.tomorrow
      this.question = this.item.question
      this.imgList = this.item.imgList
      let send_employee_list = this.item.send_employee_ids
      let send_employee_ids = []
      if(send_employee_list.length > 0){
        send_employee_list.forEach(item=>{
          send_employee_ids.push(item.employee_id)
        })
        this.$ls.set('send_employee_ids',JSON.stringify(send_employee_ids))
        this.$ls.set('send_employee_list',JSON.stringify(send_employee_list))
      }
      let send_structure_list = this.item.send_structure_ids
      let send_structure_ids = []
      if(send_structure_list.length > 0){
        send_structure_list.forEach(item=>{
          send_structure_ids.push(item.department_id)
        })
        this.$ls.set('send_structure_ids',JSON.stringify(send_structure_ids))
        this.$ls.set('send_structure_list',JSON.stringify(send_structure_list))
      }
      let check_name_list = [...send_structure_list,...send_employee_list]
      this.check_name_list = check_name_list
      this.$ls.set('check_name_list',JSON.stringify(check_name_list))
      let selectedRecord = this.item.customer_ids
      let selectedRowKeys = []
      if(selectedRecord.length > 0){
        selectedRecord.forEach(item=>{
          selectedRowKeys.push(item.customer_id)
        })
        this.selectedRecord = selectedRecord
        this.selectedRowKeys = selectedRowKeys
        this.$ls.set('localSelectedRecord',JSON.stringify(selectedRecord))
        this.$ls.set('localSelectedRowKeys',JSON.stringify(selectedRowKeys))
      }
    }
    this.visible = true
  },

  methods: {
    async getTodayCrm(){
        this.loading = true
        let res = await this.$store.dispatch('journalTodayCrmAction', {type:this.type,employee_id:this.employee_id})
        this.list = res.data.channel_follow_list
        this.target_customer = res.data.target_customer
        this.follow_total = res.data.follow_total
        this.follow_total[0].id = 1
        this.follow_total[0].employee_name = this.employee_name
        this.loading = false
    },
    async handleOk() {
      if(!this.content){
        this.$message.warning(`请填写${this.labelName}!!`)
        return false
      }
      if(!this.tomorrow){
        this.$message.warning(`请填写${this.labelNameTwo}!!`)
        return false
      }
      if(!this.question){
        this.$message.warning(`请填写遇到的问题!!`)
        return false
      }
      let send_employee_ids = JSON.parse(this.$ls.get('send_employee_ids')) || []
      let send_structure_ids = JSON.parse(this.$ls.get('send_structure_ids')) || []
      let images = []
      this.imgList.forEach(item=>{
        images.push(item.response.key)
      })
      let obj = {
        content:this.content,
        tomorrow:this.tomorrow,
        question:this.question,
        category_id:this.active
      }
      if(images.length > 0){
        obj.images = images
      }
      if(send_employee_ids.length > 0){
        obj.send_employee_ids = send_employee_ids
      }
      if(send_structure_ids.length > 0){
        obj.send_structure_ids = send_structure_ids
      }
      if(this.selectedRowKeys.length > 0){
        obj.customer_ids = this.selectedRowKeys
      }
      if(this.item && this.item.category_id){
        obj.journal_id = this.item.journal_id
      }
      this.confirmLoading = true
      await this.$store.dispatch(this.item && this.item.category_id ? 'oaUpdateAction' : 'oaAddAction', obj)
      .then(res=>{
        this.$message.success('操作成功~')
        this.parent.hideEditModal(1)
      })
      .catch(err=>{
        this.confirmLoading = false
        this.$message.error('操作失败~')
      })

    },
    async beforeUploadImg(file,fileList) {
        if (!file) { return false }
        let res = await this.$store.dispatch('imageTokenAction', {})
        if (res) {
            this.uploadParams.token = res.data.uptoken
            this.uploadParams.key = createFilePath(file.name)
            let obj = {
                uid:file.uid,
                url:this.uploadParams.key,
            }
            this.imgAddr.push(obj)
            return true
        }
        return false
    },
    async changeImg({fileList}){
      var arr = fileList
      arr.forEach((item,index)=>{
          var isJpgOrPng = item.type === 'image/jpeg' || item.type === 'image/png'
          if(!isJpgOrPng){
              arr.splice(index,1)
          }
      })
      this.imgList = arr
    },
    hideCheckWarp(val){
      if(val){
        this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
      }
      this.isCheckWarp = false
    },
    callback(e){
      this.content = ''
      this.tomorrow = ''
      this.question = ''
      if(e === 1){
        this.labelName = '今日工作'
        this.labelNameTwo = '明日工作'
        this.type = 'today'
      }
      if(e === 2){
        this.labelName = '本周工作'
        this.labelNameTwo = '下周工作'
        this.type = 'week'
      }
      if(e === 3){
        this.labelName = '本月工作'
        this.labelNameTwo = '下月工作'
        this.type = 'month'
      }
      this.getTodayCrm()
    },
    hideCustomer(val) {
      if(val){
        this.selectedRecord = JSON.parse(this.$ls.get('localSelectedRecord'))
        this.selectedRowKeys = JSON.parse(this.$ls.get('localSelectedRowKeys'))
      }
      this.isCustomer = false
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
    showSubordinate(){
      this.isSubordinate = true
    },
    hideSubordinate(val,name){
      if(val){
        this.employee_id = val
        this.employee_name = name
        this.follow_total[0].employee_name = this.employee_name
        this.getTodayCrm()
      }
      this.isSubordinate = false
    }
  },
}
</script>
