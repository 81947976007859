<template>
    <a-modal title="选择下属" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="300px" @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="选择下属" >
                <a-select :not-found-content="fetching ? undefined : null" placeholder="请选择" v-decorator="['employee_id', { rules: [{ required: true, message: '请选择下属!' }]}]" @change="handleChannge" :filterOption="filterOption" allowClear showSearch>
                    <template slot="notFoundContent">
                        <div style="height:50px">
                            <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
                        </div>
                    </template>
                    <a-select-option v-for="(d, index) of list" :key="index" :value="d.employee_id">{{ d.employee_name }}</a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    export default {
        name:"subordinate",
        inject: ['parent'],
        data() {
            return {
                visible: false,
                fetching: false,
                confirmLoading:false,
                list:[],
                form: this.$form.createForm(this),
                employee_name:''
            }
        },
        created () {
            this.visible = true
            this.getSubordinate()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getSubordinate(){
                this.fetching = true
                let res = await this.$store.dispatch('accoutSubordinateAction', {data:{employee_id:this.$store.getters.employee_id}})
                this.list = res.data
                this.fetching = false
            },
            async handleOk() {
                let params = await this.form.validateFields()
                this.parent.hideSubordinate(params.employee_id,this.employee_name)
            },
            handleChannge(value, option){
                let index = option.key
                this.employee_name = this.list[index].employee_name
            },
            handleCancel(){
                this.parent.hideSubordinate()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>